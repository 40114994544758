import "../styles/app.scss";

import page from "page";
import { pageName, propertyId, queryString, errors } from "./store";
import App from "./components/App.svelte";
import { api, sign } from "./api";

function redirect(getNewPath) {
  return (ctx) => {
    page.redirect(getNewPath(ctx));
  };
}

function navigate(name) {
  return (ctx) => {
    if (ctx.params.report) {
      pageName.set([name, ctx.params.report]);
    } else {
      pageName.set(name);
    }
    propertyId.set(ctx.params.property);
    queryString.set(ctx.querystring);
  };
}

page("/logout", function (ctx, next) {
  api.logOut(true);
  window.location.href = "https://my.communityboss.app/user";
});

page("/help", function (ctx, next) {
  window.location.href = "https://help.communityboss.app";
});

page("/:property/manager", function (ctx) {
  // off to fa land
  window.location.href = sign("https://parking.communityboss.app/" + ctx.params.property);
  //window.location.href = "https://my.communityboss.app/user/navigate?url=https://fieldagent.parkingboss.com/" + ctx.params.property + "?manager=true";
});

page("/:property/fieldagent", function (ctx) {
  // off to fa land
  window.location.href = sign("https://fieldagent.parkingboss.com/" + ctx.params.property);
});
page("/:property/enforce", function (ctx) {
  // off to fa land
  window.location.href = sign("https://fieldagent.parkingboss.com/" + ctx.params.property);
});

page("/:property/reports", navigate("list"));

page(
  "/:property/reports/permits/passes",
  redirect((ctx) => {
    const query = new URLSearchParams(ctx.querystring);
    query.set("type", "printed");
    return `/${ctx.params.property}/reports/permits?${query.toString()}`;
  })
);
page(
  "/:property/reports/permits/temporary",
  redirect((ctx) => {
    const query = new URLSearchParams(ctx.querystring);
    query.set("type", "temporary");
    return `/${ctx.params.property}/reports/permits?${query.toString()}`;
  })
);
page(
  "/:property/reports/permits/assigned",
  redirect((ctx) => {
    const query = new URLSearchParams(ctx.querystring);
    query.set("type", "assigned");
    return `/${ctx.params.property}/reports/permits?${query.toString()}`;
  })
);
page(
  "/:property/reports/permits/media",
  redirect((ctx) => {
    const query = new URLSearchParams(ctx.querystring);
    query.set("type", "assigned");
    query.set("by", "media");
    return `/${ctx.params.property}/reports/permits?${query.toString()}`;
  })
);
page(
  "/:property/reports/permits/spaces",
  redirect((ctx) => {
    const query = new URLSearchParams(ctx.querystring);
    query.set("type", "assigned");
    query.set("by", "space");
    return `/${ctx.params.property}/reports/permits?${query.toString()}`;
  })
);

page("/:property/reports/:report*", navigate("report"));
page(
  "/:property/*",
  redirect((ctx) => `/${ctx.params.property}/reports${ctx.querystring}`)
);
page(
  "/:property",
  redirect((ctx) => `/${ctx.params.property}/reports${ctx.querystring}`)
);
page("*", () => {
  errors.update((errs) =>
    errs.concat({
      id: 0 + new Date(),
      level: "warning",
      message: `Please navigate to reports from your Parking Boss login`,
    })
  );
  navigate("report");
});

page();

self["app"] = new App({ target: document.body, props: {} });
