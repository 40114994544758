<script lang="ts">
  import TableReport from "../shared/TableReport.svelte";
  import Time from "../shared/Time.svelte";
  import ValidRange from "../shared/ValidRange.svelte";

  import { endOfDay, endOfMonth, startOfDay, startOfMonth } from "date-fns";
  import { api } from "../../api";
  import { now, property, queryFrom, queryTo, queryViewpoint } from "../../store";

  import { inflate } from "../../inflate";
  import { by, rev } from "../../utils/sorting";
  import Record from "../shared/Record.svelte";

  const title = "First Assigned Permit Fees";

  $: columns = [
    { name: "Type", sort: (row) => row.name },
    { name: "Amount", sort: (row) => row?.total?.value },
    {
      name: "",
      sort: (row) => row?.authorized?.by?.type === "fee.exempt",
    },
    { name: "Date", sort: (row) => row?.authorized?.utc },
    { name: "Permit #", sort: (row) => row?.subject?.media?.display },
    {
      name: "Unit",
      sort: (row) => row?.subject?.tenant?.display,
    },
  ];

  $: defaultViewpoint = $now;
  $: defaultFrom = startOfMonth($now);
  $: defaultTo = endOfMonth($now);

  let valid, generated;

  $: fetchRows = loadRows($property.id, $queryViewpoint, $queryFrom, $queryTo);

  async function loadRows(propertyId, viewpoint, from, to) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    from = startOfDay(from || defaultFrom).toISOString();
    to = endOfDay(to || defaultTo).toISOString();

    const json = await api
      .get(`permits/continuous/fees`, {
        viewpoint,
        valid: `${from}/${to}`,
        scope: propertyId,
      })
      .then(inflate);

    valid = json.fees.valid.utc;
    generated = json.generated;

    return Object.values(json.fees.items).sort(rev(by((x) => x.authorized.utc)));
  }
</script>

{#await fetchRows}
  <TableReport {title} loading />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated} clearSort>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">{row?.name ?? ""}</th>
      <td>{row?.total?.display ?? ""}</td>
      <td>
        {#if row?.authorized?.by?.type == "fee.exempt"}
          Grace Period
        {/if}
      </td>
      <td><Time time={row?.authorized ?? ""} /></td>
      <td>
        {#if row?.subject?.media}
          <h1>
            <Record item={row.subject.media} />
          </h1>
        {/if}
      </td>
      <td>
        {#if row?.subject?.tenant}
          <h1>
            <Record item={row?.subject?.tenant} />
          </h1>
        {/if}
      </td>
    </tr>

    <span slot="controls"><ValidRange {defaultFrom} {defaultTo} /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
