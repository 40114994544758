const NONDIGIT = /\D/g;
const FIRST3 = /^(\d{3})/;
const LAST4 = /(\d{4})$/;

export function formatPhone(num) {
  if (!num) return num;
  const justNums = num.replace(NONDIGIT, "");
  if (justNums.length === 7) {
    return justNums.replace(FIRST3, "$1-");
  }
  if (justNums.length === 11 && justNums.substring(0, 1) === '1') {
    return `${justNums.substring(1, 4)}-${justNums.substring(4, 7)}-${justNums.substring(7, 11)}`
  }
  if (justNums.length === 11) {
    return `+${justNums.substring(0, 1)}-${justNums.substring(1, 4)}-${justNums.substring(4, 7)}-${justNums.substring(7, 11)}`
  }
  if (justNums.length === 10) {
    return `${justNums.substring(0, 3)}-${justNums.substring(3, 6)}-${justNums.substring(6, 10)}`
  }
  return num;
}
