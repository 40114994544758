<script lang="ts">
  import TableReport from "../shared/TableReport.svelte";
  import ValidRange from "../shared/ValidRange.svelte";

  import { endOfDay, startOfDay, subDays } from "date-fns";
  import { parse } from "duration-fns";
  import { toDays, toHours } from "../../utils/time";
  import { titleCase } from "../../utils/titleCase";

  import { api } from "../../api";
  import { inflate, modernize } from "../../inflate";

  import { now, property, queryFrom, queryTo, queryViewpoint } from "../../store";
  import { rev, vehicle } from "../../utils/sorting";
  import Record from "../shared/Record.svelte";

  $: title = `Vehicle ${titleCase($property.attendant?.title || "Safelist")} Summary`;

  $: columns = [
    { name: "Vehicle", sort: (row) => row.vehicle.display || "", comparer: vehicle },
    { name: "Used (Days)", sort: (row) => row.usedDays },
    { name: "Used (Hours)", sort: (row) => row.usedHours },
    { name: "Longest (Days)", sort: (row) => row.longestDays },
    { name: "Violations", sort: (row) => row.violationCount },
    { name: "Banned", sort: (row) => row.banned },
  ];

  let valid = null;
  let generated = null;

  $: defaultViewpoint = $now;
  $: defaultFrom = subDays($now, 4);
  $: defaultTo = $now;

  $: fetchRows = loadRows($property.id, $queryViewpoint, $queryFrom, $queryTo);

  async function loadRows(propertyId, viewpoint, from, to) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    from = startOfDay(from || defaultFrom).toISOString();
    to = endOfDay(to || defaultTo).toISOString();

    const validInterval = `${from}/${to}`;

    const json = await api.get(`locations/${propertyId}/vehicles/permits/temporary/usage`, {
      viewpoint,
      valid: validInterval,
    });

    modernize(json, { except: ["usage", "donotpermit"] });
    inflate(json);

    valid = json.valid?.utc || "/";
    generated = json.generated;

    return Object.values(json.vehicles.items)
      .map((vehicle) => {
        const usage = json.usage.items[vehicle.id] || {};
        const violationCount = json.violations?.counts?.[vehicle.id]?.count ?? 0;
        const donotpermit = json.donotpermit?.items?.[vehicle.id] || false;
        return {
          vehicle,
          usage,
          usedDays: toDays(usage.used?.duration || "PT0S").toFixed(2),
          usedHours: toHours(usage.used?.duration || "PT0S").toFixed(1),
          longestDays: toDays(usage.max?.duration || "PT0S").toFixed(1),
          violationCount,
          banned: donotpermit ? "Banned" : "No",
        };
      })
      .sort(rev((a) => toDays(parse(a.usage.used?.duration || "PT0S")) + a.violationCount));
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">
        {#if row.vehicle}
          <h1>
            <Record item={row.vehicle} />
          </h1>
        {/if}
      </th>
      <td>{row.usedDays}</td>
      <td>{row.usedHours}</td>
      <td>{row.longestDays}</td>
      <td>{row.violationCount}</td>
      <td>{row.banned}</td>
    </tr>

    <span slot="controls"><ValidRange {defaultFrom} {defaultTo} /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
