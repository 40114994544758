<script lang="ts">
  import { format, startOfDay } from "date-fns";

  const titleFormatter = "MMM d yyyy h:mm a";

  export let start;
  export let end;

  $: day = startOfDay(start);
  $: datetime = `${start.toISOString()}/${end.toISOString()}`;
  $: title = `${format(start, titleFormatter)} - ${format(end, titleFormatter)}`;
  $: time = ((start - day) / (1000 * 60 * 60 * 24)) * 100; // % of day it starts
  $: duration = ((end - start) / (1000 * 60 * 60 * 24)) * 100; // % of day it is long
  $: style = `left:${time.toFixed(2)}%; width: ${Math.ceil(duration).toFixed(2)}%;`;
</script>

<time {title} {datetime} {style} />
