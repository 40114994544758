import Index from "./Index.svelte";
import Missing from "./404.svelte";

import AccessLog from "./AccessLog.svelte";
import Banned from "./Banned.svelte";
import Notes from "./Notes.svelte";
import Permits from "./Permits.svelte";
import Scans from "./Scans.svelte";
import PermitsAssignedAccounts from "./PermitsAssignedAccounts.svelte";
import PermitsMediaFees from "./PermitsMediaFees.svelte";
import PermitsTemporaryConflicts from "./PermitsTemporaryConflicts.svelte";
import TenantsAuth from "./TenantsAuth.svelte";
import TenantsUsage from "./TenantsUsage.svelte";
import UnassignedSpaces from "./UnassignedSpaces.svelte";
import UserPermissions from "./UserPermissions.svelte";
import VehicleUsage from "./VehicleUsage.svelte";
import Violations from "./Violations.svelte";
import ViolationExceptions from "./ViolationExceptions.svelte";
import DailyPaymentsSummary from "./DailyPaymentsSummary.svelte";
import MonthlyPaymentsSummary from "./MonthlyPaymentsSummary.svelte";
import SessionAccessRecord from "./SessionAccessRecord.svelte";
import UnitDetails from "./UnitDetails.svelte";
import SpaceDetails from "./SpaceDetails.svelte";

export { Missing };

export default {
  list: Index,

  report: {
    authorizations: UserPermissions,
    notes: Notes,
    "permits/temporary/conflicts": PermitsTemporaryConflicts,
    "permits/assigned/accounts": PermitsAssignedAccounts,
    "permits/media/fees": PermitsMediaFees,
    permits: Permits,
    scans: Scans,
    "enforcement/scans": Scans,
    "spaces/unassigned": UnassignedSpaces,
    "spaces/details": SpaceDetails,
    "permittables/negative": Banned,
    "payments/days": DailyPaymentsSummary,
    "payments/months": MonthlyPaymentsSummary,
    sessions: AccessLog,
    "tenants/authentication": TenantsAuth,
    "tenants/usage": TenantsUsage,
    "vehicles/usage": VehicleUsage,
    violations: Violations,
    "violations/exceptions": ViolationExceptions,
    "enforcement/checks": SessionAccessRecord,
    "units/details": UnitDetails,
  },
};
