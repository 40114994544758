<script lang="ts">
  import TableReport from "../shared/TableReport.svelte";
  import TimeSpan from "../shared/TimeSpan.svelte";
  import ValidRange from "../shared/ValidRange.svelte";

  import { areIntervalsOverlapping, eachDayOfInterval, endOfDay, format, isSameDay, isValid, startOfDay, subDays } from "date-fns";
  import { utcToZonedTime } from "date-fns-tz";

  import { merge } from "lodash-es";
  import { api } from "../../api";
  import { inflate } from "../../inflate";
  import { now, propertyId, queryFrom, queryTo, queryViewpoint } from "../../store";
  import { by } from "../../utils/sorting";

  const title = "Access Log";

  const userAndRoleColumns = [
    { name: "User", sort: (row) => row.userName },
    { name: "Role", sort: (row) => row.role },
  ];

  $: defaultViewpoint = $now;
  $: defaultFrom = subDays($now, 7);
  $: defaultTo = $now;

  $: payload = fetchPayload($propertyId, $queryViewpoint, $queryFrom, $queryTo);

  let columns = userAndRoleColumns;
  let rows = [];
  let csvRows = [];
  let valid = null;
  let generated = null;

  async function fetchPayload(propertyId, viewpoint, from, to) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    from = startOfDay(from || defaultFrom).toISOString();
    to = endOfDay(to || defaultTo).toISOString();

    const validInterval = `${from}/${to}`;

    const [sessions, auths] = await Promise.all([
      api.get(`sessions`, {
        viewpoint,
        valid: validInterval,
        subject: propertyId,
      }),
      api.get(`authorizations`, {
        viewpoint,
        valid: validInterval,
        scope: propertyId,
      }),
    ]);

    const result = inflate(merge({}, auths, sessions));

    const resultDays = days(result);

    columns = userAndRoleColumns.concat(resultDays);
    rows = makeRows(result, resultDays);
    csvRows = downloadRows(result);
    valid = result?.authorizations?.valid?.utc || result?.authorizations?.valid || "/";
    generated = result?.generated;

    return result;
  }

  function days(json) {
    const [start, end] = (json?.sessions?.valid?.local || "/").split("/").map((x) => new Date(x));

    if (!isValid(start) || !isValid(end)) {
      return [];
    }

    return eachDayOfInterval({ start, end });
  }

  function downloadRows(json) {
    if (!json) return [];

    const authByPrincipal = Object.values(json.authorizations.items).reduce((acc, curr) => {
      acc[curr.principal.id] = curr;
      return acc;
    }, {});

    return Object.values(json.sessions.items)
      .filter((x) => typeof x.id === "object")
      .flatMap(({ id, items }) => {
        const name = id.name;
        const roles = authByPrincipal[id.id]?.roles;

        if (roles == null) return [];

        const role = showRole(roles);

        return Object.values(items || {}).map((item) => {
          const [start, end] = (item.local || "/").split("/");

          return { name, role, start, end };
        });
      });
  }

  function makeRows(json, shownDays) {
    const noSessions = shownDays.map((day) => ({ day, times: [] }));

    const sessionsByUserId = Object.values(json.sessions.items)
      .filter((x) => typeof x.id === "object")
      .reduce((acc, { id, items }) => {
        return Object.assign(acc, { [id.id]: items });
      }, {});

    return Object.values(json.authorizations.items)
      .map((auth) => {
        const [authStart, authEnd] = (auth.valid?.interval || "/").split("/");
        const authInterval = {
          start: utcToZonedTime(authStart, auth.timezone),
          end: utcToZonedTime(authEnd || new Date(), auth.timezone), //authEnd ? new Date(authEnd) : new Date(),
        };
        const overlapsWithAuth = (otherInterval) => areIntervalsOverlapping(authInterval, otherInterval);
        const authSessions = (sessionsByUserId[auth.principal.id] || [])
          .map(({ local }) => {
            const [start, end] = local.split("/");
            return {
              start: new Date(start),
              end: new Date(end),
            };
          })
          .filter(overlapsWithAuth);

        const sessionData =
          authSessions.length == 0
            ? noSessions
            : shownDays.map((day) => ({
                day,
                times: authSessions.filter((i) => isSameDay(i.start, day)),
              }));

        return {
          userName: auth.principal.name,
          role: showRole(auth.roles),
          sessionData,
        };
      })
      .sort(by("userName", "role"));
  }

  function showRole(roles) {
    if (roles?.admin) return "Manager";
    return roles?.patrol ? "Field Agent" : "";
  }
</script>

{#await payload}
  <TableReport {title} loading />
{:then}
  <TableReport {title} {columns} {rows} {valid} {generated} clearSort>
    <tr slot="headerRow" let:sortLink let:sortCol let:sortDir let:sorts>
      <th scope="col" class="no-sort"><span>{rows.length}</span></th>
      <th scope="col" class:asc={sorts[0] == "asc"} class:desc={sorts[0] == "desc"}>
        <a href={sortLink(0, sortCol, sortDir)}>User</a>
      </th>
      <th scope="col" class:asc={sorts[1] == "asc"} class:desc={sorts[1] == "desc"}>
        <a href={sortLink(1, sortCol, sortDir)}>Role</a>
      </th>
      {#each columns.slice(2) as day, ix}
        <th scope="col">
          <time datetime={day.toISOString()}>
            {#if ix % 7 == 0}{format(day, "M/d")}<br />{/if}{format(day, "EEE")}
          </time>
        </th>
      {/each}
    </tr>

    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row"><h1>{row?.userName || ""}</h1></th>
      <th scope="row"><h1>{row?.role || ""}</h1></th>
      {#each row.sessionData as { day, times }}
        <td>
          <time datetime={day.toISOString()} />
          {#each times as { start, end }}<TimeSpan {start} {end} />{/each}
        </td>
      {/each}
    </tr>

    <span slot="controls"><ValidRange {defaultFrom} /></span>

    <table style="display: none" class="download">
      <thead>
        <tr>
          <th>Name</th>
          <th>Role</th>
          <th>Start</th>
          <th>End</th>
        </tr>
      </thead>
      <tbody>
        {#each csvRows as row}
          <tr>
            <td>{row.name}</td>
            <td>{row.role}</td>
            <td>{format(new Date(row.start), "EEE MMM d yyyy h:mm a")}</td>
            <td>{format(new Date(row.end), "EEE MMM d yyyy h:mm a")}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
