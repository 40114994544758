<script lang="ts">
  import TableReport from "../shared/TableReport.svelte";
  import Time from "../shared/Time.svelte";
  import ValidRange from "../shared/ValidRange.svelte";

  import { endOfDay, format, startOfDay, subDays } from "date-fns";
  import { merge } from "lodash-es";

  import { api } from "../../api";
  import { inflate } from "../../inflate";
  import { now, property, queryFrom, queryTo, queryViewpoint } from "../../store";
  import { thumbnail } from "../../utils/filepreview";
  import { by } from "../../utils/sorting";
  import Record from "../shared/Record.svelte";

  const title = "Scans";

  $: columns = [
    {
      name: "Scanned",
      sort: ({ vehicle, media }) => vehicle?.display ?? media?.display ?? "",
    },
    {
      name: "From",
    },
    {
      name: "When",
      sort: ({ created }) => created.utc,
    },
    {
      name: "User",
      sort: ({ created }) => created?.by?.name ?? "",
    },
  ];

  let valid = null;
  let generated = null;

  $: defaultViewpoint = $now;
  $: defaultFrom = subDays($now, 7);
  $: defaultTo = $now;

  $: fetchRows = loadRows($property.id, $queryViewpoint, $queryFrom, $queryTo);

  function fakeVehicle(key) {
    if (!key || typeof key !== "string") {
      return key;
    }

    key = key.replace("O", "0");

    if (key.length <= 2) return null;
    if (key.length >= 10) return null;

    return {
      type: "vehicle",
      key: key,
      display: key,
    };
  }

  async function loadRows(propertyId, viewpoint, from, to) {
    viewpoint = format(viewpoint || defaultViewpoint, "yyyy-MM-dd'T'HH:mm:ssxxx");
    from = startOfDay(from || defaultFrom);
    to = endOfDay(to || defaultTo);

    const v = `${format(from, "yyyy-MM-dd'T'HH:mm:ss")}/${format(to, "yyyy-MM-dd'T'HH:mm:ss")}`;

    const json = await Promise.all([
      api.get(`/detections/vehicles`, {
        scope: propertyId,
        viewpoint,
        valid: v,
      }),
      api.get(`/observations`, {
        scope: propertyId,
        viewpoint,
        valid: v,
      }),
    ])
      .then((args) => merge(...args))
      .then(inflate);

    valid = json.detections.issued;
    generated = json.generated;

    return Object.values(json.detections.items)
      .concat(Object.values(json.observations.items))
      .filter((item) => {
        // Skip if missing from payload
        if (typeof item !== "object") {
          console.error("Payload missing id", item);
          return false;
        }

        // Skip if not created by a person (LPR scans of violation photos)
        if (!item.created.by?.id) {
          return false;
        }

        // Skip detections associated with an observation
        if (item.type === "detection" && item.observation?.id) {
          return false;
        }

        // Skip observations not associated with media
        if (item.type === "observation" && !item.media) {
          return false;
        }

        return true;
      })
      .map((item) => {
        item.vehicle = fakeVehicle(item.vehicle);

        return item;
      })
      .sort(by((x) => Date.parse(x.created.utc ?? x.created.datetime)));
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">
        <h1>
          <Record item={row.vehicle ?? row.media} />
        </h1>
      </th>
      <td>
        <ul class="photos files">
          {#each Object.values(row.files) as file (file.id)}
            <li class="file {file.type.split('/').join(' ')}">
              <figure>
                <a href={file.url} target="_blank">
                  <img width="44" height="44" alt="Violation" src={thumbnail(file.url, 80, 80, true)} />
                </a>
              </figure>
            </li>
          {/each}
        </ul>
      </td>
      <td>
        <Time time={row.created.utc ?? row.created.datetime} timezone={row.timezone} />
      </td>
      <td>
        <h1><data class="user">{row.created.by.name || ""}</data></h1>
      </td>
    </tr>

    <span slot="controls"><ValidRange {defaultFrom} {defaultTo} /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
