import { toSeconds, parse } from "duration-fns";
import { parseISO } from "date-fns";

export function parseTimeInterval(intervalString = "/") {
  const [startString, endString] = intervalString.split("/");
  return {
    start: (startString && parseISO(startString)) || null,
    end: (endString && parseISO(endString)) || null,
  };
}

export function toDays(duration) {
  if (typeof duration === "string") duration = parse(duration);
  return toSeconds(duration) / (60 * 60 * 24);
}

export function toHours(duration) {
  if (typeof duration === "string") duration = parse(duration);
  return toSeconds(duration) / (60 * 60);
}
