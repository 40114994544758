<script lang="ts">
  import TableReport from "../shared/TableReport.svelte";
  import Time from "../shared/Time.svelte";
  import ValidRange from "../shared/ValidRange.svelte";

  import { api } from "../../api";

  import { endOfDay, startOfDay } from "date-fns";
  import { identity } from "lodash-es";
  import { inflate, modernize } from "../../inflate";
  import { now, property, queryFrom, queryTo, queryViewpoint } from "../../store";
  import { vehicle } from "../../utils/sorting";
  import Record from "../shared/Record.svelte";

  const title = "Banned";

  $: columns = [
    { name: "Vehicle", sort: (row) => (row.container?.type == "vehicle" ? row?.container?.display : ""), comparer: vehicle },
    { name: "Unit", sort: (row) => (row.container?.type == "tenant" ? row?.container?.display : "") },
    { name: "Notes", sort: (row) => row.description },
    { name: "Banned On", sort: (row) => row?.issued?.utc },
    { name: "Banned By", sort: (row) => row?.issuer?.name },
  ];

  let valid = null;
  let generated = null;

  $: defaultViewpoint = $now;
  $: defaultFrom = $now;
  $: defaultTo = $now;

  $: fetchRows = loadRows($property.id, $queryViewpoint, $queryFrom, $queryTo);

  async function loadRows(propertyId, viewpoint, from, to) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    from = startOfDay(from || defaultFrom).toISOString();
    to = endOfDay(to || defaultTo).toISOString();

    const json = await api.get(`/locations/${propertyId}/permittables/negative`, {
      viewpoint,
      valid: `${from}/${to}`,
    });

    modernize(json);
    inflate(json);

    valid = json.permittables.valid.utc;
    generated = json.generated;

    return Object.values(json.permittables.items || {}).map((item) => ({
      issued: item.issued,
      issuer: item.issued.user,
      description: item.description,
      //notes: Object.values(json.notes[item.id]),
      container: item.container,
    }));
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <!-- TODO: data-record='permittables/{row.id}' -->
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">
        {#if row.container.type === "vehicle"}
          <h1><data class="id vehicle">{row.container.display || ""}</data></h1>
        {/if}
      </th>
      <td>
        {#if row.container.type === "tenant"}
          <Record item={row.container} />
        {/if}
      </td>
      <td>
        <ul class="notes">
          {#each [row.description].filter(identity) as text}
            <li class="note">{text}</li>
          {/each}
        </ul>
      </td>
      <td>
        <Time time={row.issued} />
      </td>
      <td>
        <h1><data class="user">{row.issuer.name}</data></h1>
      </td>
    </tr>

    <span slot="controls"><ValidRange /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
