<script context="module">
  export const DEFAULT_FORMAT_STRING = "MMM d yyyy h:mm a";
  export const DEFAULT_FORMAT_STRING_ZONED = "MMM d yyyy h:mm a zzz";
</script>

<script lang="ts">
  import { format, utcToZonedTime } from "date-fns-tz";

  export let time;
  export let timezone = undefined;
  export let label = undefined;
  let formatStringProp = null;
  export { formatStringProp as format };

  $: formatString = formatStringProp || DEFAULT_FORMAT_STRING;
  $: formatStringZoned = formatStringProp || DEFAULT_FORMAT_STRING_ZONED;

  $: local = (time && timezone && utcToZonedTime(time, timezone)) || tryDate(time?.local, time);

  function tryDate(...dateStrings) {
    for (const ds of dateStrings) {
      const ts = Date.parse(ds);
      if (isFinite(ts)) return new Date(ts);
    }
    return dateStrings[0];
  }
</script>

<time datetime={(time && time.utc) || time} title={time && time.title}>
  {#if label}
    <dfn>{label}</dfn>
  {/if}
  <slot>{local && timezone ? format(local, formatStringZoned, { timeZone: timezone }) : local ? format(local, formatString) : ""}</slot>
</time>
