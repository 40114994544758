<script lang="ts">
  import { property, roles } from "../../store";

  $: admin = $roles.admin;
  $: attendant = $property?.attendant;
  $: vehicles = $property?.vehicles;
  $: violations = $property?.violations;
  $: spaces = $property?.spaces;
  $: units = $property?.units;
</script>

<svelte:head>
  {#if $property?.name}
    <title>Reports - {$property.name}</title>
  {:else}
    <title>Reports</title>
  {/if}
</svelte:head>

{#if $property}
  <!-- <header>
    <h1>All Reports</h1>
  </header> -->
  <nav>
    <h1>General</h1>
    <ul>
      <li>
        <a href="/{$property.id}/reports/permits?type=printed">Printed Passes</a>
      </li>
      <li>
        <a href="/{$property.id}/reports/notes">Notes</a>
      </li>

      {#if admin && $property.tenants?.authentication}
        <li>
          <a href="/{$property.id}/reports/tenants/authentication">Unit Passcodes</a>
        </li>
      {/if}

      {#if !attendant && vehicles?.permits}
        <li>
          <a href="/{$property.id}/reports/permits?type=temporary">Vehicle Permits</a>
        </li>
      {/if}
    </ul>

    {#if admin}
      <h1>Team &amp; Security</h1>
      <ul>
        <li>
          <a href="/{$property.id}/reports/authorizations">User Permissions</a>
        </li>
        <li><a href="/{$property.id}/reports/sessions">Access Log</a></li>
      </ul>
    {/if}

    <h1>Enforcement</h1>
    <ul>
      <li>
        <a href="/{$property.id}/reports/enforcement/scans">Scans</a>
      </li>
      <li><a href="/{$property.id}/reports/enforcement/checks">Checks</a></li>
      {#if violations?.enabled}
        <li><a href="/{$property.id}/reports/violations">Violations</a></li>
        <li><a href="/{$property.id}/reports/violations/exceptions">Escalations</a></li>
      {/if}
      <li>
        <a href="/{$property.id}/reports/permittables/negative">Banned</a>
      </li>

      {#if admin}
        <li>
          <a href="/{$property.id}/reports/permits/temporary/conflicts">Failed Attempts to Register</a>
        </li>
      {/if}
    </ul>

    {#if attendant}
      <h1>{attendant.title}</h1>
      <ul>
        {#if admin && vehicles?.permits}
          <li>
            <a href="/{$property.id}/reports/vehicles/usage">
              Vehicle {attendant.title} Summary
            </a>
          </li>
        {/if}

        {#if admin && $property.tenants}
          <li>
            <a href="/{$property.id}/reports/tenants/usage">
              Unit {attendant.title} Summary
            </a>
          </li>
        {/if}

        {#if vehicles}
          <li>
            <a href="/{$property.id}/reports/permits?type=temporary">
              {attendant.title} Activity
            </a>
          </li>
          <li>
            <a href="/{$property.id}/reports/permits?type=special">Special Permit Activity </a>
          </li>
        {/if}
      </ul>
    {/if}

    <h1>Assigned Permits</h1>
    <ul>
      {#if admin && $property.tenants}
        <li>
          <a href="/{$property.id}/reports/permits/assigned/accounts">Assigned Permits by Unit Summary</a>
        </li>
      {/if}

      <li>
        <a href="/{$property.id}/reports/permits?type=assigned">Assigned Permits</a>
      </li>

      {#if $property.media?.enabled}
        <li>
          <a href="/{$property.id}/reports/permits?type=assigned&by=media">
            {$property.media?.title} Assignments
          </a>
        </li>
        <li>
          <a href="/{$property.id}/reports/permits?type=smart">
            {$property.media?.title} Summary
          </a>
        </li>
      {/if}

      {#if spaces && spaces.enabled}
        <li>
          <a href="/{$property.id}/reports/permits?type=assigned&by=space">Space Permits</a>
        </li>
      {/if}
    </ul>

    {#if admin && spaces && spaces.enabled}
      <h1>Spaces</h1>
      <ul>
        <li>
          <a href="/{$property.id}/reports/spaces/details">Space Details</a>
        </li>
        <li>
          <a href="/{$property.id}/reports/spaces/unassigned">Available Spaces</a>
        </li>
      </ul>
    {/if}

    {#if admin}
      <h1>Payments</h1>
      <ul>
        <li>
          <a href="/{$property.id}/reports/payments/days">Daily Payments Summary</a>
        </li>
        <li>
          <a href="/{$property.id}/reports/payments/months">Monthly Payments Summary</a>
        </li>
        {#if $property.media?.permits?.fee}
          <li>
            <a href="/{$property.id}/reports/permits/media/fees">First Assigned Permit Fees</a>
          </li>
        {/if}
      </ul>
    {/if}

    {#if units}
      <h1>Units</h1>
      <ul>
        <li>
          <a href="/{$property.id}/reports/units/details">Unit Details</a>
        </li>
      </ul>
    {/if}
  </nav>
{/if}
