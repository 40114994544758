<script lang="ts">
  import { by } from "../../../utils/sorting";
  import TableReport from "../TableReport.svelte";
  import ValidRange from "../ValidRange.svelte";

  export let title, permitsFetch;

  let permitDesciptions = [];
  let rowsObject = {};

  $: columns = [
    {
      name: "Type",
      sort: (row) => row.description,
      order: 0,
    },
    {
      name: "Quantity",
      sort: (row) => row.quantity,
      order: 1,
    },
  ].sort(by((x) => x.order));

  function getRows(json) {
    const grouped = Object.values(json.permits.items)
      .filter((permit) => permit.assigned && permit.media != null && (permit.amenity == null || permit.amenity === "parking"))
      .reduce((acc, item) => {
        if (item.media.description in acc) {
          acc[item.media.description] += 1;
        } else {
          acc[item.media.description] = 1;
        }
        return acc;
      }, new Map());

    return Object.entries(grouped)
      .map(([desc, quantity]) => ({
        description: desc === "null" || desc === "undefined" ? "Other" : desc,
        quantity,
      }))
      .sort(by("description", "quantity"));
  }
</script>

{#await permitsFetch}
  <TableReport {title} loading />
{:then permitsPayload}
  <TableReport {title} {columns} rows={getRows(permitsPayload)} valid={permitsPayload.permits.valid} generated={permitsPayload.generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <td>
        <ul class="notes">
          <li class="note">
            {row.description}
          </li>
        </ul>
      </td>
      <td>
        <ul class="notes">
          <li class="note">
            {row.quantity}
          </li>
        </ul>
      </td>
    </tr>

    <span slot="controls"><ValidRange /></span>
  </TableReport>
{/await}
