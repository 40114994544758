import { isUndefined } from "lodash-es";

const collator = new Intl.Collator(undefined, {
  numeric: true,
  caseFirst: "lower",
});

export const vehicle = new Intl.Collator(undefined, {
  numeric: false,
  sensitivity: "base",
});

export const text = new Intl.Collator(undefined, {
  numeric: false,
  caseFirst: "lower",
});

export const mixed = new Intl.Collator(undefined, {
  numeric: true,
  caseFirst: "lower",
});

export const numeric = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

export function byCustom(comparer = mixed, ...keys) {
  comparer = comparer.compare || comparer;
  return (objA, objB) => {
    for (const key of keys) {
      const valA = get(objA, key);
      const valB = get(objB, key);
      const undefinedA = isUndefined(valA);
      const undefinedB = isUndefined(valB);

      if(undefinedA || undefinedB)
      {
        if(!undefinedB){
          return 1;
        }
        if(!undefinedA){
          return -1;
        }
      }
      else {
        const res = comparer(valA, valB );
        if (res) return res;
      }   

    }
    return 0;
  };
}

export function by(...keys) {
  return (objA, objB) => {
    for (const key of keys) {
      const res = collator.compare(get(objA, key), get(objB, key));
      if (res) return res;
    }
    return 0;
  };
}

export function rev(fn) {
  return (a, b) => -fn(a, b);
}

function get(obj, getter) {
  const getterType = typeof getter;
  if (getterType === "string") return obj[getter];
  if (getterType === "function") return getter(obj);
  throw new Error("Expected a string or function getter");
}
