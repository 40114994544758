<script lang="ts">
  import TableReport from "../TableReport.svelte";
  import Time from "../Time.svelte";
  import ValidRange from "../ValidRange.svelte";

  import { identity } from "lodash-es";
  import { formatPhone } from "../../../formatPhone";
  import { getContacts } from "../../../getContacts";
  import { thumbnail } from "../../../utils/filepreview";
  import { by, vehicle } from "../../../utils/sorting";
  import Record from "../Record.svelte";

  export let title, permitsFetch;

  let maxfiles = 1;

  $: columns = [
    {
      name: "Type",
      sort: (permit) => permit.issued.policy?.title,
    },
    {
      name: "Vehicle",
      sort: (permit) => permit.vehicle?.display,
      comparer: vehicle,
    },
    {
      name: "Unit",
      sort: (permit) => permit.tenant?.display,
    },
    {
      name: "Space",
      sort: (permit) => permit.spaces?.[0]?.display,
    },
    {
      name: "Permit #",
      sort: (permit) => permit.media?.display,
    },
    {
      name: "Start",
      sort: (permit) => permit.valid?.min?.utc,
    },
    {
      name: "End",
      sort: (permit) => permit.valid?.max?.utc ?? "When Revoked",
    },
    {
      name: "Status",
      sort: (permit) => permit.revoked?.datetime,
    },
    {
      name: "Name",
      sort: (permit) => permit.contacts?.map((c) => c.name).join("\n"),
    },
    {
      name: "Email",
      sort: (permit) => permit.contacts?.map((c) => c.email).join("\n"),
    },
    {
      name: "Phone",
      sort: (permit) => permit.contacts?.map((c) => c.phone).join("\n"),
    },
    {
      name: "Notes",
      sort: (permit) => permit.description,
    },
    {
      name: "Issued",
      sort: (permit) => permit.issued?.utc,
    },
    {
      name: "User",
      sort: (permit) => permit.issued?.user?.name ?? "",
    },
    {
      name: "Fees",
      sort: (permit) => permit.fees?.reduce?.((acc, val) => acc + val.total.value, 0),
    },
    {
      name: "Photos",
      count: maxfiles,
      //sort: (row) => row.files?.length,
    },
  ];

  function getRows(json) {
    const rows = Object.values(json.permits.items)
      .filter((x) => !x.continuous && !x.assigned && (x.amenity == null || x.amenity === "parking") && x.issued?.user)
      .map((permit) => {
        return {
          ...permit,
          files: Object.entries(json.attachments.items[permit.id] || {}).reduce((result, [id, type]) => {
            if (type != "file") return result;
            //if (!json.items[id]) return result;
            result.push(json.items[id]);
            return result;
          }, []),
          fees: Object.values(permit.fees ?? {}),
          contacts: getContacts(permit, json).sort(by("name")),
          //notes: Object.values(permit.notes ?? {}).sort(by((x) => x.issued.utc)),
        };
      })
      .sort(by("visiting"));
    maxfiles = rows.reduce((count, r) => Math.max(count, r.files.length), maxfiles);
    return rows;
  }
</script>

{#await permitsFetch}
  <TableReport {title} loading />
{:then json}
  <TableReport {title} {columns} rows={getRows(json)} valid={json.permits.valid} generated={json.generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">
        <h1>
          <data value={row.issued.policy?.policy}>{row.issued.policy?.title ?? row.title}</data>
        </h1>
      </th>
      <td>
        {#if row.vehicle}
          <h1>
            <Record item={row.vehicle} />
          </h1>
        {/if}
      </td>
      <td>
        {#if row.tenant}
          <h1>
            <Record item={row.tenant} />
          </h1>
        {/if}
      </td>
      <td>
        {#if row.spaces}
          {#each row.spaces as space}
            <h1><Record item={space} /></h1>
          {/each}
        {/if}
      </td>
      <td>
        {#if row.media}
          <h1>
            <Record item={row.media} />
          </h1>
        {/if}
      </td>
      <td><Time time={row.valid.min.datetime} timezone={row.timezone} /></td>
      <td>
        {#if row.valid.max}
          <Time time={row.valid.max.datetime} timezone={row.timezone} />
        {:else}
          <time datetime="">When Revoked</time>
        {/if}
      </td>
      <td>
        {#if row.revoked}
          <Time label={row.revoked.title} time={row.revoked.datetime} timezone={row.timezone} />
        {/if}
        <!-- {["Cancelled", "Revoked"].includes(row.valid?.max?.title) ? row.valid?.max?.title : ""} -->
      </td>

      <td>
        <ul class="notes">
          {#each row.contacts as contact (contact.uuid)}
            <li class="contact">{contact.name ?? ""}</li>
          {/each}
        </ul>
      </td>
      <td>
        <ul class="notes">
          {#each row.contacts as contact (contact.uuid)}
            {#if contact.email}
              <li class="contact">
                <a href="mailto:{contact.email}">
                  {contact.email}
                </a>
              </li>
            {/if}
          {/each}
        </ul>
      </td>
      <td>
        <ul class="notes">
          {#each row.contacts as contact (contact.uuid)}
            {#if contact.phone}
              <li class="contact">
                <a href="tel:{contact.phone}">
                  {formatPhone(contact.phone)}
                </a>
              </li>
            {/if}
          {/each}
        </ul>
      </td>
      <td>
        <ul class="notes">
          {#each [row.description].filter(identity) as text}
            <li class="note">{text}</li>
          {/each}
        </ul>
      </td>
      <td><Time time={row.issued.datetime} timezone={row.timezone} /></td>
      <td>
        <h1>
          <data class="user">
            {row.issued?.user?.name ?? ""}
          </data>
        </h1>
      </td>
      <td>
        {#if row.fees && row.fees.length > 0}
          <ul>
            {#each row.fees as fee}
              {@debug fee}
              <li>{fee.total.display}</li>
            {/each}
          </ul>
        {/if}
      </td>
      {#if (row.files?.length || 0) > 0}
        {#each row.files as file}
          <td>
            <ul class="photos files">
              <li class="file {file.type.split('/').join(' ')}">
                <figure>
                  <a href={file.url} target="_blank">
                    <img width="44" height="44" alt="Violation" src={thumbnail(file.url, 80, 80, true)} />
                  </a>
                </figure>
              </li>
            </ul>
          </td>
        {/each}
      {/if}
      {#if (row.files?.length || 0) < maxfiles}
        <td colspan={maxfiles - (row.files?.length || 0)} />
      {/if}
    </tr>

    <span slot="controls"><ValidRange /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
