<script lang="ts">
  import TableReport from "../shared/TableReport.svelte";
  import Time from "../shared/Time.svelte";
  import ValidRange from "../shared/ValidRange.svelte";

  import { endOfDay } from "date-fns";
  import { api } from "../../api";
  import { inflate, modernize } from "../../inflate";
  import { now, property, queryOn, queryViewpoint } from "../../store";
  import Record from "../shared/Record.svelte";

  const title = "Enforcement Escalations";

  $: columns = [
    {
      name: "Target",
      sort: ({ subject }) => subject.display,
    },
    {
      name: "Action",
      sort: ({ action }) => action,
    },
    {
      name: "Threshold",
      sort: ({ limit }) => limit.count,
    },
    {
      name: "Has",
      sort: ({ count }) => count,
    },
    {
      name: "Last Violation",
      sort: ({ latest }) => latest.utc,
    },
  ];

  let valid = null;
  let generated = null;

  $: defaultViewpoint = $now;
  $: defaultOn = $now;

  $: fetchRows = loadRows($property.id, $queryViewpoint, $queryOn);

  async function loadRows(propertyId, viewpoint, on) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    on = endOfDay(on || defaultOn).toISOString();

    const json = await api.get(`/locations/${propertyId}/violations/exceptions`, {
      viewpoint,
      valid: on,
    });
    if (json.violations.thresholds) {
      modernize(json);
      inflate(json);
    } else {
      for (const item of Object.values(json.violations.exceptions?.items ?? [])) {
        if (item.subject) item.subject = json.items[item.subject] ?? item.subject;
      }
    }

    valid = json.requested;
    generated = json.generated;

    return Object.values(json.violations.exceptions?.items ?? {}).map(({ title, subject, latest, timezone, limit, count }) => {
      return {
        action: title,
        subject,
        threshold: limit.display,
        count,
        limit,
        latest: { utc: latest, timezone: timezone },
      };
    });
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">
        <h1>
          <Record item={row.subject} />
        </h1>
      </th>
      <td>
        {row.action}
      </td>
      <td>
        {row.limit.display}
      </td>
      <td>
        {row.count}
      </td>
      <td>
        <Time time={row.latest.utc} timezone={row.latest.timezone} />
      </td>
    </tr>

    <ValidRange showTo={false} fromName="on" defaultFrom={defaultOn} />
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
