import { parseISO, isValid } from "date-fns";

export function tryInt(str, def = -1) {
  const result = parseInt(str, 10);

  return Number.isNaN(result) ? def : result;
}

export function tryDate(str, def = null) {
  const result = parseISO(str);

  return isValid(result) ? result : def;
}
