<script lang="ts">
  import { help } from "../../utils/help";
</script>

<footer class="menu">
  <ul class="system">
    <li><a href="/help" on:click={help}>Get Help</a></li>
    <li><a href="https://my.communityboss.app/user">Account</a></li>
    <li><a href="/logout">Log Out</a></li>
  </ul>
</footer>
