<script lang="ts">
  import TableReport from "../shared/TableReport.svelte";
  import ValidRange from "../shared/ValidRange.svelte";

  import { api } from "../../api";
  import { inflate, modernize } from "../../inflate";
  import { property } from "../../store";
  import { titleCase } from "../../utils/titleCase";

  import { endOfDay, startOfDay } from "date-fns";
  import { now, queryFrom, queryTo, queryViewpoint } from "../../store";
  import { by } from "../../utils/sorting";
  import Record from "../shared/Record.svelte";

  const title = "Assigned Permits by Unit Summary";

  $: columns = [
    {
      name: "Unit",
      sort: (row) => row?.display,
    },
    { name: "Vehicles", sort: (row) => row.vehicleCount },
    { name: "Spaces", sort: (row) => row.spaceCount },
    {
      name: titleCase($property?.media?.title ?? "Smart Decals"),
      sort: (row) => row.mediaCount,
    },
  ];

  let valid = null;
  let generated = null;

  $: defaultViewpoint = $now;
  $: defaultFrom = $now;
  $: defaultTo = $now;

  $: fetchRows = loadRows($property.id, $queryViewpoint, $queryFrom, $queryTo);

  async function loadRows(propertyId, viewpoint, from, to) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    from = startOfDay(from || defaultFrom).toISOString();
    to = endOfDay(to || defaultTo).toISOString();

    const validRange = `${from}/${to}`;

    const tenantsJson = await api
      .get(`locations/${propertyId}/tenants`, {
        viewpoint,
        valid: validRange,
      })
      .then(modernize)
      .then(inflate);

    const permitsJson = await api
      .get("permits", {
        scope: propertyId,
        viewpoint,
        valid: validRange,
      })
      .then(inflate);

    generated = permitsJson.generated;
    valid = permitsJson.permits.valid;

    const permitsByTenantKey = Object.values(permitsJson.permits.items)
      .filter((x) => !!x.tenant)
      .reduce((acc, permit) => {
        if (!acc[permit.tenant.key]) {
          acc[permit.tenant.key] = [];
        }

        acc[permit.tenant.key].push(permit);

        return acc;
      }, {});

    return Object.values(tenantsJson.tenants.items)
      .map((tenant) => {
        const tenantPermits = permitsByTenantKey[tenant.key] || [];

        return {
          ...tenant,
          vehicleCount: tenantPermits.filter((x) => x.vehicle).reduce((acc, permit) => acc.add(permit.vehicle), new Set()).size,
          spaceCount: tenantPermits.flatMap((x) => x.spaces).reduce((acc, space) => acc.add(space), new Set()).size,
          mediaCount: tenantPermits.filter((x) => x.media).reduce((acc, permit) => acc.add(permit.media), new Set()).size,
        };
      })
      .sort(by("display"));
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">
        <h1><Record item={row} /></h1>
      </th>
      <td>{row.vehicleCount || "0"}</td>
      <td>{row.spaceCount || "0"}</td>
      <td>{row.mediaCount || "0"}</td>
    </tr>

    <span slot="controls"><ValidRange /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
