<script lang="ts">
  import { sign } from "../../api";

  export let property;
  export let admin = false;
  export let agent = false;
</script>

{#if property}
  <nav class="menu">
    <ul>
      <li>
        <a href="/{property.id}/reports" title="Reports Listing">All Reports</a>
      </li>
      <li>
        <a href="/{property.id}/reports/sessions" title="Access Log">Access Log</a>
      </li>
    </ul>

    <ul>
      {#if admin}
        <li>
          <a
            class="manager"
            href={sign(property.manager?.url ?? `https://parking.communityboss.app/${property.id}`)}
            title={property.manager?.title ?? "Manager"}>{property.manager?.title ?? "Manager"}</a>
        </li>
      {/if}
      {#if admin || agent}
        <li>
          <a href="/{property.id}/enforce" title="Field Agent">Field Agent</a>
        </li>
      {/if}
    </ul>
  </nav>
{/if}
