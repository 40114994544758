import Pages, { Missing } from "../components/views";
import { derived } from "svelte/store";
import { pageName } from "./global";

export const page = derived(pageName, ($pn) => {
  if (!Array.isArray($pn)) {
    $pn = [$pn];
  }

  if ($pn.length == 0) return Missing;

  return $pn.reduce((acc, key) => (acc == null ? acc : acc[key]), Pages) ?? Missing;
});
