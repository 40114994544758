export function modernize(json, { except = [] } = {}) {
  const keys = Object.keys(json).filter((key) => !except.includes(key) && typeof json[key]?.items === "object");

  json.items = Object.assign({}, json.items);

  keys.forEach((key) => {
    Object.assign(json.items, json[key].items);
    json[key].items = Object.keys(json[key].items).reduce((acc, id) => {
      if (id in acc) {
        console.error(`${id} is already in the items, do you need to except ${key} or somethign else?`);
      }
      acc[id] = id;
      return acc;
    }, {});
  });

  return json;
}

export function inflate(json) {
  const { items, attachments } = json;

  inflateObject(json, items);
  if (attachments) {
    addAttachments(items, attachments.items);
  }

  return json;
}

export function attachContacts(json) {
  Object.entries(json.contacts?.items || {}).forEach(([id, contact]) => {
    if (json.items[id]) {
      json.items[id].contact = contact;
    }
  });
  return json;
}

function inflateObject(obj, items) {
  Object.entries(obj).forEach(([key, value]) => {
    if (key !== "access:shared") inflateKey(obj, key, value, items);
  });
}

function inflateKey(parent, key, value, items) {
  if (value && typeof value === "object" && !Array.isArray(value)) {
    inflateObject(value, items);
  } else if (Array.isArray(value)) {
    parent[key] = value.map((arrItem) => {
      if (typeof arrItem === "string") {
        return items[arrItem] ?? arrItem;
      }
      if (typeof arrItem === "object" && !Array.isArray(arrItem)) {
        inflateObject(arrItem, items);
      }
      return arrItem;
    });
  } else if (typeof value === "string" && value in items) {
    const inflated = items[value];
    if (inflated !== parent) {
      parent[key] = inflated;
    }
  }
}

function addAttachments(items, attachments) {
  Object.values(items).forEach((item) => {
    if (!attachments[item.id]) return;

    const itemAttachments = Object.entries(attachments[item.id])
      .map(([key, val]) => [key, val + "s"])
      .reduce((acc, [id, type]) => {
        if (!acc[type]) {
          acc[type] = {};
        }
        acc[type][id] = items[id];
        return acc;
      }, {});

    Object.assign(item, itemAttachments);
  });
}
