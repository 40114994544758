const unitPrefixes = {
  apartment: "APT",
  suite: "STE",
};

export function unitPrefixed(unit, format) {
  if (!unit) return;
  if (!format) return unit;
  return (unitPrefixes[format] || "UNIT") + " " + unit;
}
