<script context="module">
</script>

<script lang="ts">
  import RecordItem from "./record/RecordItem.svelte";

  export let item;
  export let exclude = [];
</script>

<RecordItem url={false} {item} {exclude} />
