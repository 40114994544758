import { derived } from "svelte/store";
import { api } from "../api";
import { propertyId } from "./global";
import { authorizations } from "./property";

export const roles = derived([authorizations, propertyId, api.user], ([$authorizations, $propertyId, $user]) => {
  if (!$authorizations) return {};

  if ($authorizations.authorizations?.system) {
    return { admin: true, owner: true, patrol: true };
  }
  let matchingAuths = Object.values($authorizations.authorizations.items).filter(
    (auth) => auth.principal.id === $user.user && $propertyId === (auth.scope.id || auth.scope)
  );

  if (matchingAuths.length === 1) {
    return Object.assign({}, matchingAuths[0].roles);
  }

  console.group(`${matchingAuths.length} Auth Matches!`);
  console.error(matchingAuths, { principal: $user.user, scope: $propertyId });
  console.groupEnd();

  return { admin: false, owner: false, patrol: false };
});
