import { saveAs } from "file-saver";

const MIME = { type: "text/csv;charset=utf-8" };

export function downloadNearestTableAsCsv(e, filename) {
  if (e.preventDefault) e.preventDefault();

  const figure = e.target ? e.target.closest("figure") : e;

  const table = figure.querySelector("table.download") || figure.querySelector("table");
  filename = filename || (typeof e?.target?.getAttribute === "function" && e.target.getAttribute("download"));

  exportTable(table, filename || "report.csv");
}

function exportTable(table, filename) {
  const csv = tableToCsv(table);
  const blob = new Blob([csv], MIME);
  saveAs(blob, filename);
}

function tableToCsv(table) {
  const rows = [...(table || root).querySelectorAll("thead tr, tbody tr")];

  return rows
    .map((row) => {
      const columns = [...row.querySelectorAll(":is(th, td):not([role='presentation'])")];

      return columns
        .map((column) => {
          const asText = columnToText(column);
          return `"${asText.replace(/"/g, '""')}"`;
        })
        .join(",");
    })
    .join("\r\n");
}

function columnToText(column) {
  const photos = [...column.querySelectorAll("ul.photos a")];
  if (photos.length > 0) {
    return photos.map((a) => a.href).reduce((acc, curr) => acc + " " + curr);
  }
  return column.textContent.trim();
}
