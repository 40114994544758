import { derived } from "svelte/store";
import { queryString } from "./global";
import { tryDate } from "../conversion";

export const query = derived(queryString, (q) => {
  const parsed = new URLSearchParams(q);
  return Array.from(parsed.keys()).reduce((acc, key) => {
    acc[key] = parsed.getAll(key);

    if (acc[key].length === 1) {
      acc[key] = acc[key][0];
    }

    return acc;
  }, {});
});

export const queryViewpoint = derived(query, ($query) => $query && tryDate($query.viewpoint));
export const queryFrom = derived(query, ($query) => $query && tryDate($query.from));
export const queryTo = derived(query, ($query) => $query && tryDate($query.to));

export const queryOn = derived(query, ($query) => $query && tryDate($query.on));
