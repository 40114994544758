<script lang="ts">
  import TableReport from "../shared/TableReport.svelte";
  import ValidRange from "../shared/ValidRange.svelte";

  import { endOfDay } from "date-fns";

  import { api } from "../../api";
  import { inflate } from "../../inflate";
  import { by } from "../../utils/sorting";

  import { now, propertyId, queryOn, queryViewpoint } from "../../store";
  import Record from "../shared/Record.svelte";

  const title = `Unit Passcodes`;

  $: columns = [
    {
      name: "Unit",
      sort: ({ display }) => display,
    },
    {
      name: "Passcode",
      sort: ({ passcodes }) => passcodes.join(" ").trim(),
    },
  ];

  let valid = null;
  let generated = null;

  $: defaultViewpoint = $now;
  $: defaultOn = $now;

  $: fetchRows = loadRows($propertyId, $queryViewpoint, $queryOn);

  async function loadRows(propertyId, viewpoint, on) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    const validOn = endOfDay(on || defaultOn).toISOString();

    const json = await api.get(`locations/${propertyId}/tenants`, {
      viewpoint,
      valid: validOn,
      authentication: true,
    });
    json.items = {};
    ["locations", "tenants"].forEach((key) => {
      Object.assign(json.items, json[key].items);
      json[key].items = Object.keys(json[key].items).reduce((acc, id) => {
        acc[id] = id;
        return acc;
      }, {});
    });

    const result = inflate(Object.assign({}, json));
    valid = result.authentication?.valid?.utc || "/";
    generated = result.generated;

    return Object.values(result.tenants?.items || {})
      .map((tenant) => ({
        display: tenant.display,
        type: tenant.type || json.tenants.type,
        format: tenant.format,
        passcodes: Object.values(json.authentication.items[tenant.id] || {}),
      }))
      .sort(by((a) => (a.display ?? a.key ?? "").toUpperCase()));
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row"
        ><h1>
          <Record item={row} />
        </h1></th>
      <td>
        <ul class="notes">
          {#each row.passcodes as passcode}
            <li class="auth">{passcode}</li>
          {/each}
        </ul>
      </td>
    </tr>

    <span slot="controls">
      <ValidRange showTo={false} fromName="on" defaultFrom={defaultOn} />
    </span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
