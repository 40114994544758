<script lang="ts">
  import { api } from "../../api";

  import Footer from "./Footer.svelte";
  import Header from "./Header.svelte";
  import Nav from "./Nav.svelte";

  import { property, propertyLoading, roles } from "../../store";

  const user = api.user;

  $: admin = $roles.admin;
  $: agent = $roles.agent;
</script>

{#if $propertyLoading}
  <header><h1>Loading...</h1></header>
{:else if !$property}
  <header><h1>Property Not Found</h1></header>
{:else}
  <Header name={$property.name} address={$property.address} />
  <Nav property={$property} userId={$user.user} {admin} {agent} />
{/if}
<Footer />
