import { get, writable } from "svelte/store";
import { api } from "../api";
import { errors, propertyId } from "./global";
import { inflate } from "../inflate";

export const propertyLoading = writable(true);

export const property = writable(null);
export const authorizations = writable(null);
async function load(id) {
  const viewpoint = new Date().toISOString();

  const [property, authorizations] = await Promise.all([
    api.get("properties", { scope: id, viewpoint }).then(inflate),
    api
      .get("authorizations", {
        principal: "self",
        scope: id,
        viewpoint,
        valid: `${viewpoint}/${viewpoint}`,
      })
      .then(inflate),
  ]);

  return {
    property: property.properties.items[Object.keys(property.properties.items)[0]],
    authorizations,
  };
}

function setStores(data = {}) {
  property.set(data.property || null);
  authorizations.set(data.authorizations || null);
}

propertyId.subscribe(async (id) => {
  if (!id) {
    return;
  }

  propertyLoading.set(true);
  setStores();

  load(id)
    .catch((err) => {
      errors.update((errs) => errs.concat(err));
      return {};
    })
    .then((storeData) => {
      if (get(propertyId) === id) {
        setStores(storeData);
        propertyLoading.set(false);
      }
    });
});
