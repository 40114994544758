<script context="module">
  const touch = "ontouchstart" in window || (window.DocumentTouch && document instanceof DocumentTouch);
</script>

<script lang="ts">
  import { format, isValid, parseISO } from "date-fns";
  import { query } from "../../store";
  import DateInput from "./DateInput.svelte";

  const formatUrl = (date) => format(date, "yyyy-MM-dd");

  function getDate(date, def = null) {
    const d = parseISO(date);
    if (isValid(d)) return d;
    return def == null ? new Date() : def;
  }

  function makeLink(range) {
    const url = new URL(location.toString());

    url.searchParams.set(fromName, formatUrl(range.from));
    if (showTo) {
      url.searchParams.set(toName, formatUrl(range.to));
    }

    return url.toString();
  }

  export let showTo = true;
  export let fromName = "from";
  export let toName = "to";
  export let defaultFrom = null;
  export let defaultTo = null;

  let from = getDate($query[fromName], defaultFrom);
  let to = showTo && getDate($query[toName], defaultTo);
</script>

<form on:submit={(e) => e.preventDefault()}>
  <fieldset class="valid">
    <fieldset class="datetime">
      <label for="valid-min">{showTo ? "From" : "Date"}</label>
      <!-- <input id="valid-min" type="date" name={fromName} bind:value={from} /> -->
      <DateInput id="valid-min" name={fromName} bind:value={from} />
      <!-- {#if touch}
      
    {:else}
      <Calendar name={fromName} bind:value={from} />
    {/if} -->
    </fieldset>
    {#if showTo}
      <fieldset class="datetime">
        <label for="valid-max">To</label>
        <!-- <input type="date" name={toName} bind:value={to} /> -->
        <DateInput id="valid-max" name={toName} bind:value={to} />
        <!-- <h1>{""}</h1>
      {#if touch}
        <input type="date" name={toName} bind:value={to} />
      {:else}
        <Calendar name={toName} bind:value={to} />
      {/if} -->
      </fieldset>
    {/if}
  </fieldset>
  <a href={makeLink({ from, to })}>{""}</a>
</form>
