<script lang="ts">
  import TableReport from "../shared/TableReport.svelte";
  import Time from "../shared/Time.svelte";
  import ValidRange from "../shared/ValidRange.svelte";

  import { endOfDay, startOfDay, subDays } from "date-fns";
  import { identity } from "lodash-es";
  import { api } from "../../api";
  import { inflate } from "../../inflate";
  import { now, property, queryFrom, queryTo, queryViewpoint } from "../../store";
  import { thumbnail } from "../../utils/filepreview";
  import { by, vehicle } from "../../utils/sorting";
  import Record from "../shared/Record.svelte";

  const title = "Violations";

  let maxfiles = 1;

  $: columns = [
    {
      name: "Vehicle",
      sort: ({ vehicle }) => vehicle?.display,
      comparer: vehicle,
    },
    {
      name: "Unit",
      sort: ({ tenant }) => tenant?.display,
    },
    {
      name: "Warning",
      sort: ({ warning }) => warning?.display,
    },
    {
      name: "Recorded",
      sort: ({ issued }) => issued?.utc,
    },
    {
      name: "User",
      sort: ({ issuer }) => issuer?.utc,
    },
    {
      name: "Notes",
      sort: ({ issued }) => issued.reason,
    },
    {
      name: "Fine",
      sort: ({ fees }) => fees?.total.value ?? 0,
    },
    {
      name: "Paid",
      sort: ({ fees }) => fees?.payments.total.value ?? -1,
    },
    {
      name: "Location",
      sort: ({ geometry }) => (geometry ? 1 : 0),
    },
    {
      name: "Photos",
      count: maxfiles,
      //sort: (row) => row?.files?.length ?? 0,
    },
  ];

  let valid = null;
  let generated = null;

  $: defaultViewpoint = $now;
  $: defaultFrom = subDays($now, 30);
  $: defaultTo = $now;

  $: fetchRows = loadRows($property.id, $queryViewpoint, $queryFrom, $queryTo);

  async function loadRows(propertyId, viewpoint, from, to) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    from = startOfDay(from || defaultFrom).toISOString();
    to = endOfDay(to || defaultTo).toISOString();

    const issued = `${from}/${to}`;

    const json = await api.get(`/violations`, {
      viewpoint,
      issued,
      scope: propertyId,
      files: true,
      payments: true,
    });

    //modernize(json, { except: ["files"] });
    inflate(json);

    valid = json.violations.issued;
    generated = json.generated;

    maxfiles = Object.values(json.violations.items).reduce(
      (count, item) => Math.max(count, Object.values(json.files.items[item.id] || item.files || {}).length),
      maxfiles
    );

    console.log("json=", json);

    return Object.values(json.violations.items)
      .map((item) => ({
        files: Object.values(json.files.items[item.id] || item.files || {}),
        issued: item.issued,
        issuer: item.issued.by,
        //notes: Object.values(json.notes[item.id] || {}),
        geometry: item.geometry,
        space: item.space,
        tenant: item.tenant,
        vehicle: item.vehicle,
        warning: item.warning,
        fees: json.fees?.["for"]?.[item.id],
      }))
      .sort(by((x) => Date.parse(x.issued.utc)));
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">
        {#if row.vehicle}
          <h1>
            <Record item={row.vehicle} />
          </h1>
        {/if}
      </th>
      <th scope="row">
        {#if row.tenant}
          <Record item={row.tenant} />
        {/if}
      </th>
      <td>
        {#if row.warning}
          <data class="id smart warning" value={row.warning.id}>{row.warning.display || ""}</data>
        {/if}
      </td>
      <td>
        <Time time={row.issued} />
      </td>
      <td>
        <h1><data class="user">{row.issued.by.name || ""}</data></h1>
      </td>
      <td>
        <ul class="notes">
          {#each [row.issued.reason].filter(identity) as text}
            <li class="note">{text}</li>
          {/each}
        </ul>
      </td>
      <td>
        {#if row.fees}
          <data class="fees" value={row.fees.total.value}>{row.fees.total.display}</data>
        {/if}
      </td>
      <td>
        {#if row.fees}
          <data class="paid" value={row.fees.payments.full}>{row.fees.payments.full ? "Paid" : "Unpaid"}</data>
        {/if}
      </td>
      <td>
        <data class="position" value={(row.geometry?.coordinates || []).join(",")}>{row.geometry ? "Recorded" : "No data"}</data>
      </td>
      {#if (row.files?.length || 0) > 0}
        {#each row.files as file}
          <td>
            <ul class="photos files">
              <li class="file {file.type.split('/').join(' ')}">
                <figure>
                  <a href={file.url} target="_blank">
                    <img width="44" height="44" alt="Violation" src={thumbnail(file.url, 80, 80, true)} />
                  </a>
                </figure>
              </li>
            </ul>
          </td>
        {/each}
      {/if}
      {#if (row.files?.length || 0) < maxfiles}
        <td colspan={maxfiles - (row.files?.length || 0)} />
      {/if}
    </tr>

    <span slot="controls"><ValidRange {defaultFrom} {defaultTo} /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
