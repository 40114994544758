<script lang="ts">
  import { format, parseISO } from "date-fns";

  export let id = "";
  export let value = new Date();
  export let name = "date";
  export let formatString = "EEE, MMM d, yyyy";

  function change(e) {
    if (e.target.value) value = parseISO(e.target.value);
  }
</script>

<data class="date" value={format(value, "yyyy-M-d")}>
  <span>{format(value, formatString)}</span>
  <input
    {id}
    {name}
    value={format(value, "yyyy-M-d")}
    type="date"
    class="date"
    on:input={change}
    on:change={change}
    on:click={(e) => !e.target.disabled && !e.target.readOnly && e.target.showPicker && e.target.showPicker()}
    required
    min="2000-01-01"
    max="2100-31-12" />
</data>
