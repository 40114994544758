<script lang="ts">
  import Address from "../shared/Address.svelte";

  export let name, address;
</script>

<header>
  <h1>{name}</h1>
  {#if address}
    <h2><Address {address} /></h2>
  {/if}
</header>
<a href="#collapse" id="collapse" class="collapse"
  ><svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"><path d="m6 9 6 6 6-6" /></svg
  ></a>
<a href="#expand" id="expand" class="expand"
  ><svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"><path d="m18 15-6-6-6 6" /></svg
  ></a>
