<script lang="ts">
  import { differenceInMinutes, endOfDay, startOfDay, subDays } from "date-fns";
  import { toDate as parseAsUtc } from "date-fns-tz";
  import { api } from "../../api";
  import { inflate } from "../../inflate";
  import { now, propertyId, queryFrom, queryTo, queryViewpoint } from "../../store";
  import { by } from "../../utils/sorting";
  import RecordItem from "../shared/Record.svelte";
  import TableReport from "../shared/TableReport.svelte";
  import Time from "../shared/Time.svelte";
  import ValidRange from "../shared/ValidRange.svelte";

  const title = "Checks";

  $: columns = [
    {
      name: "For",
      sort: ({ subject }) => ((subject.type || "") + (subject.display || "")).toLowerCase(),
    },
    {
      name: "Start",
      sort: ({ intervalStart }) => intervalStart,
    },
    {
      name: "End",
      sort: ({ intervalEnd }) => intervalEnd,
    },
    {
      name: "Duration",
      sort: ({ duration }) => duration,
    },
    {
      name: "User",
      sort: ({ user }) => user.name,
    },
    {
      name: "Permit Status",
      sort: ({ permitted }) => !!permitted,
    },
    {
      name: "Permitted For",
      //sort: ({ permitted }) => !!permitted,
    },
    {
      name: "Location",
      sort: ({ geometry }) => (geometry ? 1 : 0),
    },
  ];

  let valid = null;
  let generated = null;

  $: defaultViewpoint = $now;
  $: defaultFrom = subDays($now, 7);
  $: defaultTo = $now;

  $: fetchRows = loadRows($propertyId, $queryViewpoint, $queryFrom, $queryTo);

  async function loadRows(propertyId, viewpoint, from, to) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    from = startOfDay(from || defaultFrom).toISOString();
    to = endOfDay(to || defaultTo).toISOString();

    const validInterval = `${from}/${to}`;

    const json = await api.get(`enforcement/checks`, {
      viewpoint,
      valid: validInterval,
      scope: propertyId,
    });
    const result = inflate(Object.assign({}, json));

    valid = result.accessed?.valid || "/";
    generated = result.generated;

    return Object.values(result.items)
      .filter(({ type }) => "accessed" === type)
      .map((item) => {
        const splitInterval = item.interval.split("/");

        return (
          item && {
            user: item.principal,
            subject: item.subject,
            duration: Math.max(differenceInMinutes(parseAsUtc(splitInterval[1]), parseAsUtc(splitInterval[0])), 1),
            intervalStart: splitInterval[0],
            intervalEnd: splitInterval[1],
            fullItem: item,
            geometry: item.geometry,
            permitted: Array.isArray(item.permitted) ? item.permitted.map((i) => result.items[i] ?? i) : item.permitted,
          }
        );
      })
      .sort(by((x) => Date.parse(x.datetime)));
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <td>
        <h1>
          <RecordItem item={row.subject} />
        </h1>
      </td>
      <td><Time time={row.intervalStart} timezone={row.fullItem.timezone} /></td>
      <td><Time time={row.intervalEnd} timezone={row.fullItem.timezone} /></td>
      <td>{row.duration} min</td>
      <td><h1><data class="user">{row.user?.name || ""}</data></h1></td>

      <td
        ><data class="permitted" value={null == row.permitted ? "" : !!row.permitted}
          >{!!row.permitted ? "Valid permit at time of check" : false === row.permitted ? "No valid permit at time of check" : ""}</data
        ></td>
      <td
        >{#each row.permitted || [] as item}
          <RecordItem {item} />
        {/each}</td>
      <td>
        <data class="position" value={(row.geometry?.coordinates || []).join(",")}>{row.geometry ? "Recorded" : "No data"}</data>
      </td>
    </tr>

    <span slot="controls"><ValidRange {defaultFrom} /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
